import dynamic from 'next/dynamic';

import { useFavorites, useGlobal } from '@hultafors/eripiowear/hooks';
import { EripiowearProduct } from '@hultafors/eripiowear/types';

import StarYellowSvg from '../../svg/star-yellow.svg';
import { Grid } from '../grid/grid';
import { GridChild } from '../grid-child/grid-child';
import { ProductCard } from '../product-card/product-card';

import { HeaderInner } from './favorites.styled';

const SlideOut = dynamic(() =>
  import('@hultafors/shared/components').then((mod) => mod.SlideOut),
);

const SlideOutHeader = dynamic(() =>
  import('@hultafors/shared/components').then((mod) => mod.SlideOutHeader),
);

const ClearButton = dynamic(() =>
  import('@hultafors/shared/components').then((mod) => mod.ClearButton),
);

export const FAVORITES_DRAWER_ID = 'FavoritesDrawer';

export interface FavoritesProps {
  isOpen: boolean;
  toggleMenu(force?: boolean): void;
  header: string;
  clearText: string;
  newText: string;
}

export const Favorites: React.FC<FavoritesProps> = ({
  toggleMenu,
  isOpen,
  header,
  clearText,
  newText,
}) => {
  const { globalContent } = useGlobal();
  const { favorites, deleteFavorites } = useFavorites();

  const onClick = () => toggleMenu(false);

  const slideOutHeader = (
    <SlideOutHeader onClick={onClick} closeLabel={globalContent.close || ''}>
      <HeaderInner>
        <StarYellowSvg
          aria-hidden={true}
          focusable={false}
          width={16}
          height={16}
        />
        {header}
        <ClearButton
          onClick={deleteFavorites}
          disabled={!favorites?.length}
          title={clearText}
        />
      </HeaderInner>
    </SlideOutHeader>
  );

  return (
    <SlideOut isOpen={isOpen} toggle={toggleMenu} header={slideOutHeader}>
      <Grid columns={[{ columns: 4 }, { breakpoint: 'mobileMax', columns: 6 }]}>
        {favorites.map((product, i) => {
          return (
            <GridChild
              key={i}
              columnSpan={[
                { columns: 2 },
                { breakpoint: 'mobileMax', columns: 3 },
              ]}
            >
              <ProductCard
                product={product as EripiowearProduct}
                inFavorites
                isSmall
                badgeText={newText}
                msrp={globalContent.msrp || ''}
                msrpIncludingVat={globalContent.msrpIncludingVat || ''}
              />
            </GridChild>
          );
        })}
      </Grid>
    </SlideOut>
  );
};
