import {
  ContentPlugsBlockFragment,
  DualImageFragment,
  FactBoxFragment,
  GridOneThreeFragment,
  HeroFragment,
  ImageBlockFragment,
  ImageTextBlockDoubleFragment,
  ImageTextBlockSimpleFragment,
  LinkBlockFragment,
  ProductListFragment,
  ProductSearchResultsFragment,
  QuoteFragment,
  TeaserBlockFragment,
  TextBlockFragment,
  VideoFragment,
  VisibleSeoBlockFragment,
} from '../dato';

export function isHero(item: any): item is HeroFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'HeroRecord';
}

export function isTeaserBlock(item: any): item is TeaserBlockFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'TeaserBlockRecord';
}

export function isProductList(item: any): item is ProductListFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'ProductListRecord';
}

export function isTextBlock(item: any): item is TextBlockFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'TextBlockRecord';
}

export function isVisibleSeoBlock(item: any): item is VisibleSeoBlockFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'VisibleSeoBlockRecord';
}

export function isQuote(item: any): item is QuoteFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'QuoteRecord';
}

export function isFactBox(item: any): item is FactBoxFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'FactBoxRecord';
}

export function isImageBlock(item: any): item is ImageBlockFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'ImageRecord';
}

export function isVideo(item: any): item is VideoFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'VideoRecord';
}

export function isDualImageBlock(item: any): item is DualImageFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'DualImageRecord';
}

export function isLinkBlock(item: any): item is LinkBlockFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'LinkBlockRecord';
}

export function isProductSearchResults(
  item: any,
): item is ProductSearchResultsFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'ProductSearchResultRecord';
}

export function isImageTextBlockDouble(
  item: any,
): item is ImageTextBlockDoubleFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'ImageTextBlockDoubleRecord';
}

export function isImageTextBlockSimple(
  item: any,
): item is ImageTextBlockSimpleFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'ImageTextBlockSimpleRecord';
}

export function isGridOneThree(item: any): item is GridOneThreeFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'GridOneThreeRecord';
}

export function isContentPlugsBlock(
  item: any,
): item is ContentPlugsBlockFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'ContentPlugsBlockRecord';
}
