import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, fontSizes, lineHeights } from '@hultafors/eripiowear/helpers';

export const ListStyled = styled.div`
  line-height: ${lineHeights.bodyMobile};
  color: ${colors.black};
  font-size: ${fontSizes.body};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    line-height: ${lineHeights.bodyDesktop};
    font-size: ${fontSizes.body};
  }

  ul {
    padding-left: 1rem;

    li {
      margin: 1rem 0;
    }
  }
`;
