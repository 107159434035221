import { useGlobal } from '@hultafors/eripiowear/hooks';

import { FavoriteStyled } from './favorite.styled';
import RemoveSvg from './remove.svg';
import StarSvg from './star.svg';

export interface FavoriteProps {
  toggleFavorite(...args: any[]): unknown;
  isFavorite: boolean;
  product: any;
  inFavorites?: boolean;
}

export const Favorite: React.FC<FavoriteProps> = ({
  product,
  isFavorite,
  inFavorites,
  toggleFavorite,
}) => {
  const {
    globalContent: { addToFavorites = '', removeFromFavorites = '' },
  } = useGlobal();

  return (
    <FavoriteStyled
      className={`Favorite ${isFavorite ? 'Active ' : ''} ${
        inFavorites ? 'InFavorites' : ''
      }`}
      onClick={() => toggleFavorite(product, isFavorite)}
      aria-label={(inFavorites ? removeFromFavorites : addToFavorites) || ''}
    >
      {inFavorites
        ? (
          <RemoveSvg aria-hidden={true} focusable={false} />
          )
        : (
          <StarSvg aria-hidden={true} focusable={false} />
          )}
    </FavoriteStyled>
  );
};
