import Image from 'next/image';

import { createProductImageSrc } from '@hultafors/eripiowear/helpers';
import { EripiowearProduct } from '@hultafors/eripiowear/types';

import noImage from './noimage.png';
import {
  CloseButton,
  ImageWrapper,
  ProductColor,
  ProductColorImage,
  ProductColorLabel,
  ProductDetails,
  ProductDetailsItem,
  ProductImage,
  ProductInfo,
  ProductListItemLink,
  ProductListItemStyled,
  ProductName,
} from './product-list-item.styled';
import RemoveSvg from './remove.svg';

export interface ProductListItemProps {
  product: EripiowearProduct;
  removeItem?(...args: any[]): unknown;
  sizeText?: string;
  narrow?: boolean;
  slugs?: string[];
}

export const ProductListItem: React.FC<ProductListItemProps> = ({
  product,
  narrow,
  slugs,
  removeItem,
  sizeText,
}) => {
  const href = `/${[
    ...(slugs || ['products', product.parentSlug, product.categorySlug]),
    product.productId,
  ]
    .filter(Boolean)
    .join('/')}`;

  return (
    <ProductListItemStyled>
      <ProductListItemLink href={href}>
        <ProductImage $narrow={narrow}>
          <ImageWrapper>
            {product.image?.url && (
              <Image
                src={createProductImageSrc(product.image?.url, noImage)}
                fill
                style={{ objectFit: 'contain' }}
                sizes="200px"
                alt={product.name}
              />
            )}
          </ImageWrapper>
        </ProductImage>

        <ProductInfo>
          <ProductDetails>
            <ProductDetailsItem>{product.sku}</ProductDetailsItem>
            {product.category && (
              <ProductDetailsItem>{product.category}</ProductDetailsItem>
            )}
          </ProductDetails>

          <ProductName>{product.description || product.name}</ProductName>

          {product.colors?.[0] && (
            <ProductColor>
              <ProductColorImage
                src={product.colors[0].image?.url}
                alt={product.colors[0].text}
              />
              <ProductColorLabel>{product.colors[0].text}</ProductColorLabel>
            </ProductColor>
          )}
        </ProductInfo>

        {removeItem && (
          <CloseButton onClick={(e) => removeItem?.(e, product)}>
            <RemoveSvg
              focusable="false"
              aria-hidden="true"
              width={16}
              height={16}
            />
          </CloseButton>
        )}
      </ProductListItemLink>
    </ProductListItemStyled>
  );
};
