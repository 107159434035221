import { Grid } from '../grid/grid';
import { GridChild } from '../grid-child/grid-child';

import { Heading, RelatedProductsStyled } from './related-products.styled';

export interface RelatedProductsProps {
  children?: React.ReactNode;
  header?: string;
}

export const RelatedProducts: React.FC<RelatedProductsProps> = ({
  header = '',
  children,
}) => {
  if (!children) {
    return;
  }
  return (
    <RelatedProductsStyled>
      <Grid>
        <GridChild
          columnSpan={[
            { columns: 4 },
            { breakpoint: 'mobileMax', columns: 12 },
          ]}
        >
          <Heading>{header}</Heading>
        </GridChild>
      </Grid>
      {children}
    </RelatedProductsStyled>
  );
};
