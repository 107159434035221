import { GaugeStyled } from './gauge.styled';

export interface GaugeProps {
  soFar: unknown;
  toGo: unknown;
}

export const Gauge: React.FC<GaugeProps> = (props) => (
  <GaugeStyled className="Gauge">
    <div className="SoFar" style={{ width: props.soFar + '%' }} />
    <div className="ToGo" style={{ width: props.toGo + '%' }} />
  </GaugeStyled>
);
