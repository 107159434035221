import { useFavorites } from '@hultafors/eripiowear/hooks';

import { Badge } from '../badge/badge';

import FavoritesSvg from './favorites.svg';
import { HeaderStoreItemStyled, IconText } from './header-store-item.styled';
export interface HeaderStoreItemProps {
  isFavorite?: boolean;
  onClick: React.MouseEventHandler;
  iconText?: string;
  children?: React.ReactNode;
}

export const HeaderStoreItem: React.FC<HeaderStoreItemProps> = ({
  children,
  iconText,
  onClick,
  isFavorite,
}) => {
  const { favorites } = useFavorites();
  return (
    <HeaderStoreItemStyled $isFavorite={isFavorite} onClick={onClick}>
      {isFavorite && (
        <>
          <div className="Favorite">
            <FavoritesSvg />
            <Badge text={favorites.length} tiny round />
          </div>
          {iconText && <IconText>{iconText}</IconText>}
          {children}
        </>
      )}
    </HeaderStoreItemStyled>
  );
};
