import { MarkdownToJSX } from 'markdown-to-jsx';

import { StyledMarkdown } from './markdown.styled';

export interface MarkdownProps {
  [key: string]: any;
  children?: string;
  options?: MarkdownToJSX.Options;
}

export const Markdown: React.FC<MarkdownProps> = ({
  children,
  options,
  ...rest
}) => {
  return (
    <StyledMarkdown options={options} {...rest}>
      {children ?? ''}
    </StyledMarkdown>
  );
};
