import { ParttrapImage } from '@hultafors/shared/types';

import {
  ProductDetailData,
  RawProductModel,
} from '@hultafors/eripiowear/types';

export const getFirstImage = ({
  images,
}: ProductDetailData | RawProductModel): ParttrapImage | null => {
  return (images?.[0]?.url && { url: images?.[0].url }) || null;
};
