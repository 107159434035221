import dynamic from 'next/dynamic';

import { createUrl } from '@hultafors/eripiowear/helpers';
import { useGlobal } from '@hultafors/eripiowear/hooks';
import {
  LinkListFragment,
  MenuItemFragment,
  PageLinkFragment,
  SubMenuSectionFragment,
} from '@hultafors/eripiowear/types';

import CloseSvg from '../../svg/close.svg';
import { SlideIn, SlideInToggler } from '../slide-in/slide-in';

import {
  MenuLink,
  MenuLinkLabel,
  MenuLinksWrapper,
  MenuStyled,
  MenuWrapper,
  SearchWrapper,
} from './menu.styled';

const Accordion = dynamic(() =>
  import('../accordion/accordion').then((mod) => mod.Accordion),
);
const AccordionItem = dynamic(() =>
  import('../accordion-item/accordion-item').then((mod) => mod.AccordionItem),
);
const SearchBox = dynamic(() =>
  import('../search-box/search-box').then((mod) => mod.SearchBox),
);

export const MOBILE_MENU_DRAWER_ID = 'MobileMenuDrawer';

export interface MenuProps {
  isOpen: boolean;
  toggleMenu: SlideInToggler;
  headerText: string;
}

export const Menu: React.FC<MenuProps> = ({
  toggleMenu,
  isOpen,
  headerText,
}) => {
  const { globalContent, allMenuItems } = useGlobal();

  function sectionMapper(section: SubMenuSectionFragment) {
    return section.content.map(linkListMapper);
  }

  function linkListMapper(list: LinkListFragment) {
    if (!list.link?.slug) {
      return null;
    }
    const key = `LinkListBlock-${list.id}`;
    let label: React.ReactNode = '';
    if (list.link?.shortTitle || list.link?.title) {
      label = (
        <MenuLinkLabel>{list.link.shortTitle || list.link.title}</MenuLinkLabel>
      );
    }
    if (list.items?.length) {
      return (
        <AccordionItem
          level={2}
          label={label}
          key={`mobileMenuSubItem-${list.link?.id}`}
          menu
        >
          <MenuLinksWrapper>
            {list.items.filter(Boolean).map(grandChildMapper)}
          </MenuLinksWrapper>
        </AccordionItem>
      );
    }
    return (
      <MenuLink key={key} href={list.link.slug} $level={1}>
        {list.link?.shortTitle ?? list.link?.title ?? list.link.slug}
      </MenuLink>
    );
    function grandChildMapper(grandChild: PageLinkFragment) {
      if (!grandChild.slug) {
        return null;
      }
      return (
        <MenuLink
          key={`mobileMenuSubSubItem-${grandChild.id}`}
          aria-level={3}
          $level={2}
          href={grandChild.slug}
        >
          {grandChild.shortTitle ?? grandChild.title}
        </MenuLink>
      );
    }
  }

  const secondaryMapper = (menuItem: MenuItemFragment) => {
    if (!menuItem?.page?.slug) {
      return null;
    }
    return (
      <MenuLink
        key={`mobileMenuItem-${menuItem.id}`}
        href={createUrl(menuItem.page.slug || '')}
      >
        {menuItem.page.title}
      </MenuLink>
    );
  };

  const menuItemMapper = (item: MenuItemFragment) => {
    if (!item) {
      return null;
    }

    const sections: SubMenuSectionFragment[] = item.sections?.filter(Boolean);

    if (sections?.length) {
      return (
        <AccordionItem
          level={1}
          label={item.label ? <MenuLinkLabel>{item.label}</MenuLinkLabel> : ''}
          key={`mobileMenuItem-${item.id}`}
          menu
        >
          <Accordion allowZeroExpanded>
            <div>{sections.map(sectionMapper)}</div>
          </Accordion>
        </AccordionItem>
      );
    }
    return (
      <MenuLink
        key={`mobileMenuItem-${item.id}`}
        href={createUrl(item.page?.slug || '')}
      >
        {item.label || ''}
      </MenuLink>
    );
  };
  return (
    <MenuStyled>
      <SlideIn
        toggle={toggleMenu}
        isOpen={isOpen}
        headerText={headerText}
        HeaderIcon={CloseSvg}
        isMenu
        padContent
        partnerPortalUrl={globalContent.partnerPortalUrl || ''}
        id={MOBILE_MENU_DRAWER_ID}
      >
        <SearchWrapper>
          <SearchBox
            tall
            isOpen={isOpen}
            toggleMenu={toggleMenu}
            isAutoComplete={true}
            inMobileMenu={true}
          />
        </SearchWrapper>
        <MenuWrapper>
          <Accordion allowZeroExpanded>
            {allMenuItems?.filter(Boolean)?.map(menuItemMapper)}
          </Accordion>
        </MenuWrapper>
      </SlideIn>
    </MenuStyled>
  );
};
