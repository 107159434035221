import { MobileMenuWrapperStyled } from './mobile-menu-wrapper.styled';

interface MobileMenuWrapperProps {
  children?: React.ReactNode;
  className?: string;
}

export const MobileMenuWrapper: React.FC<MobileMenuWrapperProps> = ({
  className,
  children,
}) => {
  return (
    <MobileMenuWrapperStyled className={className}>
      {children}
    </MobileMenuWrapperStyled>
  );
};
