import { useContext } from 'react';

import { GlobalContext } from '@hultafors/eripiowear/context';

export const useGlobal = () => {
  const context = useContext(GlobalContext);
  if (!context) {
    throw new Error('useGlobal must be used within GlobalContext');
  }
  return context;
};
