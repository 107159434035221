import LoadingCircleSvg from '../../svg/loading-circle.svg';

import { LoaderStyled } from './loader.styled';

interface LoaderProps {
  small?: boolean;
  tiny?: boolean;
  center?: boolean;
}

export const Loader: React.FC<LoaderProps> = ({ small, tiny, center }) => {
  let size = 24;
  if (tiny) {
    size = 20;
  }
  return (
    <LoaderStyled $small={small} $tiny={tiny} $center={center}>
      <LoadingCircleSvg
        width={size}
        height={size}
        aria-hidden={true}
        focusable={false}
      />
    </LoaderStyled>
  );
};
