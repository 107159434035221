import { ProductPriceStyled, SmallInfo } from './product-price.styled';

export interface ProductPriceProps {
  children?: React.ReactNode;
  extra?: string;
}

export const ProductPrice: React.FC<ProductPriceProps> = ({
  children,
  extra,
}) => (
  <ProductPriceStyled>
    {children}
    {extra && <SmallInfo>{extra}</SmallInfo>}
  </ProductPriceStyled>
);
