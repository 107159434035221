import { ParsedProduct } from '@hultafors/shared/types';

import { useGlobal } from '@hultafors/eripiowear/hooks';
import { EripiowearProduct } from '@hultafors/eripiowear/types';

import { EmptyProducts } from '../empty-products/empty-products';
import { ProductCard } from '../product-card/product-card';

export interface ProductsProps {
  products?: ParsedProduct[];
  badgeText: string;
  isLoading?: boolean;
  numberOfItems?: number;
  slugs?: string[];
}

export const Products: React.FC<ProductsProps> = ({
  products = [],
  badgeText,
  isLoading,
  numberOfItems = 12,
  slugs = [],
}) => {
  const { globalContent } = useGlobal();
  const productMapper = (product: ParsedProduct) => (
    <ProductCard
      key={`p-${product.productId}`}
      product={product as EripiowearProduct}
      badgeText={badgeText}
      msrp={globalContent.msrp || ''}
      msrpIncludingVat={globalContent.msrpIncludingVat || ''}
      slugs={slugs}
    />
  );
  return isLoading
    ? (
      <EmptyProducts pageSize={numberOfItems} />
      )
    : (
      <>{products?.map(productMapper)}</>
      );
};
