import { CSSProperties } from 'styled-components';

import { BoxStyled } from './box.styled';

export interface BoxProps {
  children?: React.ReactNode;
  marginTop?: CSSProperties['marginTop'];
  shadow?: boolean;
}

export const Box: React.FC<BoxProps> = ({ children, shadow, marginTop }) => (
  <BoxStyled $shadow={shadow} $marginTop={marginTop}>
    {children}
  </BoxStyled>
);
