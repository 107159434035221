import styled from 'styled-components';

interface PageStyledProps {
  $fullscreen?: boolean;
}

export const PageStyled = styled.main<PageStyledProps>`
  width: 100%;
  max-width: 100%;
  height: 100%;
  margin-inline: auto;
  box-sizing: border-box;
  display: block;
  flex: 1;
`;
