import { MenuItemFragment } from '@hultafors/eripiowear/types';

import { routes } from './routes';
/**
 * @desc Use this to generate every url on the site. Creates outgoing links and makes sure internal link starts with a /
 * @param url {string}
 * @returns
 */
export const createUrl = (url: string) => {
  if (!url) {
    return '/';
  }
  // external link
  if (url.startsWith('http')) {
    return url;
  }

  if (url.startsWith('/')) {
    url = url.substring(1, url.length);
  }
  return `/${url}`;
};

export const createDetailPageUrl = ({
  productId,
  slugs = [],
}: {
  productId: string | number;
  slugs?: string[];
}) => {
  return `/${[...slugs, productId].filter(Boolean).join('/')}`;
};

/**
 * @desc Creates url for the products dropdown menu opened from the main topbar nav
 * @param {object} {parentNode: {pageLink: {id:string, slug:string}}}
 * @returns {string}
 */
export const createProductsMenuUrl = ({
  parentNode,
  currentNode,
}: {
  parentNode?: MenuItemFragment;
  currentNode?: MenuItemFragment;
}): string => {
  let result = `/${routes.PRODUCTS}/`;

  if (parentNode?.page?.slug) {
    result += `${parentNode.page.slug}/`;
  }
  if (currentNode?.page?.slug) {
    result += `${currentNode.page.slug}`;
  }

  return result;
};

/**
 * @desc
 * @param {string} pathName
 * @param {object} pageLink
 * @returns {string}
 */
export const createContentPageUrl = ({
  pathName,
  pageLink,
}: {
  pathName: string;
  pageLink: { slug: string };
}) => {
  if (!pathName || !pageLink) {
    return '/';
  }
  pathName = removeFirstSlashInPath(pathName);
  return `/${pathName}/${pageLink.slug}`;
};

const removeFirstSlashInPath = (url: string) => {
  if (url.startsWith('/')) {
    url = url.substring(1, url.length);
  }
  if (url.endsWith('/')) {
    url = url.substring(0, url.length - 1);
  }
  return url;
};
