import { useId } from 'react';

import { EmptyProduct } from '../empty-product/empty-product';

export interface EmptyProductsProps {
  pageSize: number;
}

export const EmptyProducts: React.FC<EmptyProductsProps> = ({ pageSize }) => {
  const uid = useId();
  if (!pageSize) {
    return null;
  }
  return (
    <>
      {Array.from(Array(pageSize))?.map((_item, i) => (
        <EmptyProduct key={`EmptyProduct-${uid}-${i}`} />
      ))}
    </>
  );
};
