import { Noto_Sans } from '@next/font/google';

const notoSans = Noto_Sans({
  weight: ['400', '700'],
  style: ['normal', 'italic'],
  subsets: ['latin'],
  display: 'swap',
});

interface FontsProps {
  children?: React.ReactNode;
  style?: React.CSSProperties;
}

export const Fonts: React.FC<FontsProps> = ({ children, style }) => {
  return (
    <div className={notoSans.className} style={style}>
      <style jsx global>
        {`
        html,
        input,
        textarea,
        button,
        select {
        }
      `}
      </style>
      {children}
    </div>
  );
};
