import { CSSProperties } from 'styled-components';

import { useGlobal } from '@hultafors/eripiowear/hooks';

import { TextButton } from '../text-button/text-button';

import { DialogContent, DialogHeader, DialogWrapper } from './dialog.styled';

export interface DialogProps {
  children?: React.ReactNode;
  closeButtonLabel?: React.ReactNode;
  onClose?: React.MouseEventHandler;
  height?: CSSProperties['height'];
  width?: CSSProperties['width'];
  top?: CSSProperties['top'];
  right?: CSSProperties['right'];
  bottom?: CSSProperties['bottom'];
  left?: CSSProperties['left'];
  isFullscreen?: boolean;
}

export const Dialog: React.FC<DialogProps> = ({
  children,
  closeButtonLabel,
  onClose,
  height,
  width,
  top,
  right,
  bottom,
  left,
  isFullscreen,
}) => {
  const { globalContent } = useGlobal();
  return (
    <DialogWrapper
      $height={height}
      $width={width}
      $top={top}
      $right={right}
      $bottom={bottom}
      $left={left}
    >
      <DialogHeader $isFullscreen={isFullscreen}>
        <TextButton
          data-test="DialogClose"
          onClick={onClose}
          aria-label={globalContent.close}
        >
          {closeButtonLabel}
        </TextButton>
      </DialogHeader>
      <DialogContent>{children}</DialogContent>
    </DialogWrapper>
  );
};
