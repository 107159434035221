import {
  ProductDetailData,
  ProductListData,
} from '@hultafors/eripiowear/types';

import { transformDetailPageProduct } from './transformDetailPageProduct';
import { transformListPageProduct } from './transformListPageProduct';

/**
 * @desc Checks if product parameter comes from list view or detail page
 * @param object product
 * @return boolean
 */
const isDetailPageProduct = (product: ProductListData | ProductDetailData) => {
  // Detail page products has different structure than list view product
  return typeof product.sku === 'string' ? false : true;
};

/**
 * @desc Transforms "list view" or "detail page" product to minimal representation needed for favorites, cart and auto complete search
 * @param object product
 * @return object (product representation)
 */
export const transformProduct = (
  product: ProductListData | ProductDetailData,
) =>
  isDetailPageProduct(product)
    ? transformDetailPageProduct(product as ProductDetailData)
    : transformListPageProduct(product as ProductListData);
