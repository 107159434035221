import Markdown from 'markdown-to-jsx';
import styled from 'styled-components';

import { colors, fontSizes, fontWeights } from '@hultafors/eripiowear/helpers';

export const StyledMarkdown = styled(Markdown)`
  a {
    text-decoration: underline;

    &:hover {
      color: ${colors.gray1};
    }
  }

  h2 {
    font-weight: ${fontWeights.normal};
    font-size: ${fontSizes.header2};
    line-height: ${fontSizes.header2};
  }

  h3 {
    font-size: ${fontSizes.heroTitle};
    line-height: ${fontSizes.heroTitle};
    font-weight: ${fontWeights.normal};
  }

  h4 {
    font-weight: ${fontWeights.normal};
    font-size: ${fontSizes.l};
    line-height: ${fontSizes.l};
  }
`;
