import { Fragment, useId } from 'react';

import dynamic from 'next/dynamic';

import {
  isContentPlugsBlock,
  isDualImageBlock,
  isFactBox,
  isGridOneThree,
  isHero,
  isImageBlock,
  isLinkBlock,
  isProductList,
  isProductSearchResults,
  isQuote,
  isTeaserBlock,
  isTextBlock,
  isVideo,
  isVisibleSeoBlock,
  LandingPageFragment,
  PageFragment,
} from '@hultafors/eripiowear/types';

import { ProductListProps } from '../product-list/product-list';

const Hero = dynamic(() =>
  import('../hero/hero').then((module) => module.Hero),
);

const TeaserBlock = dynamic(() =>
  import('../teaser-block/teaser-block').then((module) => module.TeaserBlock),
);

const ProductList = dynamic(() =>
  import('../product-list/product-list').then((module) => module.ProductList),
);

const TextBlock = dynamic(() =>
  import('../text-block/text-block').then((module) => module.TextBlock),
);

const VisibleSeoBlock = dynamic(() =>
  import('../visible-seo-block/visible-seo-block').then(
    (module) => module.VisibleSeoBlock,
  ),
);

const Quote = dynamic(() =>
  import('../quote/quote').then((module) => module.Quote),
);

const FactBox = dynamic(() =>
  import('../fact-box/fact-box').then((module) => module.FactBox),
);

const ImageBlock = dynamic(() =>
  import('../image-block/image-block').then((module) => module.ImageBlock),
);

const Video = dynamic(() =>
  import('../video-player/video-player').then((module) => module.VideoPlayer),
);

const DualImageBlock = dynamic(() =>
  import('../dual-image-block/dual-image-block').then(
    (module) => module.DualImageBlock,
  ),
);

const LinkBlock = dynamic(() =>
  import('../document-link-block/document-link-block').then(
    (module) => module.DocumentLinkBlock,
  ),
);

const ProductSearchResults = dynamic(() =>
  import('../product-search-results/product-search-results').then(
    (module) => module.ProductSearchResults,
  ),
);

const GridOneThree = dynamic(() =>
  import('../grid-one-three/grid-one-three').then((mod) => mod.GridOneThree),
);

const ContentPlugsBlock = dynamic(() =>
  import('../content-plugs-block/content-plugs-block').then(
    (mod) => mod.ContentPlugsBlock,
  ),
);

interface ContentAreaProps {
  content: PageFragment['content'] | LandingPageFragment['content'];
}

export const ContentArea: React.FC<ContentAreaProps> = ({ content }) => {
  const uid = useId();
  const contentMapper = (item: any, index: number) => {
    const key = `ContentArea-${uid}-${item.id}`;
    if (isHero(item)) {
      return <Hero key={key} priority={index === 0} {...item} />;
    }
    if (isTeaserBlock(item)) {
      return <TeaserBlock key={key} {...item} />;
    }
    if (isProductList(item)) {
      return <ProductList key={key} {...(item as ProductListProps)} />;
    }
    if (isTextBlock(item)) {
      return <TextBlock key={key} {...item} />;
    }
    if (isVisibleSeoBlock(item)) {
      return <VisibleSeoBlock key={key} {...item} />;
    }
    if (isQuote(item)) {
      return <Quote key={key} {...item} />;
    }
    if (isFactBox(item)) {
      return <FactBox key={key} {...item} />;
    }
    if (isImageBlock(item)) {
      return <ImageBlock key={key} priority={index === 0} {...item} />;
    }
    if (isVideo(item)) {
      return <Video key={key} {...item} />;
    }
    if (isDualImageBlock(item)) {
      return <DualImageBlock key={key} priority={index === 0} {...item} />;
    }
    if (isLinkBlock(item)) {
      return <LinkBlock key={key} {...item} />;
    }
    if (isProductSearchResults(item)) {
      return <ProductSearchResults key={key} {...item} />;
    }
    if (isGridOneThree(item)) {
      return <GridOneThree key={key} {...item} />;
    }
    if (isContentPlugsBlock(item)) {
      return <ContentPlugsBlock key={key} {...item} />;
    }
    return null;
  };
  return <Fragment>{content.map(contentMapper)}</Fragment>;
};
