import { ProductDetailsStyled } from './product-details.styled';

export interface ProductDetailsProps {
  children?: React.ReactNode;
  className?: string;
  large?: boolean;
  noBottomMargin?: boolean;
  card?: boolean;
}

export const ProductDetails: React.FC<ProductDetailsProps> = ({
  large,
  noBottomMargin,
  className,
  children,
}) => {
  return (
    <ProductDetailsStyled
      $large={large}
      $noBottomMargin={noBottomMargin}
      className={className}
    >
      {children}
    </ProductDetailsStyled>
  );
};
