import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

export const MobileMenuWrapperStyled = styled.div`
  margin-inline-start: -20px;
  width: 100vw;
  padding-inline: 20px;

  @media screen and (min-width: ${breakpoints.bigMobile}) {
    width: 400px;
  }
`;
