import styled from 'styled-components';

import { colors, fontSizes, fontWeights } from '@hultafors/eripiowear/helpers';

export const ProductPriceStyled = styled.div`
  position: relative;
  font-size: 20px;
  font-weight: ${fontWeights.normal};
  letter-spacing: 1px;
  margin-block-end: 24px;
`;
export const SmallInfo = styled.span`
  margin-left: 12px;
  color: ${colors.gray2};
  font-size: ${fontSizes.mini1};
  font-weight: ${fontWeights.normal};
  letter-spacing: 0.2px;
`;
