import { EmptyProductStyled } from './empty-product.styled';

interface EmptyProductProps {
  className?: string;
  style?: React.CSSProperties;
}

export const EmptyProduct: React.FC<EmptyProductProps> = ({
  className,
  style,
}) => {
  return (
    <EmptyProductStyled className={className} style={style}>
      <div className="ImagePlaceholder" />
      <div className="ProductInfoPlaceholder">
        <div className="ProductDetailsPlaceholder">
          <span />
          <span />
        </div>
        <span className="ProductNamePlaceholder" />
      </div>
    </EmptyProductStyled>
  );
};
