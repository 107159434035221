import Head from 'next/head';
import { useRouter } from 'next/router';

import { Language } from '@hultafors/shared/types';

import { useGlobal } from '@hultafors/eripiowear/hooks';
import { LandingPageFragment, PageFragment } from '@hultafors/eripiowear/types';

import { ContentArea } from '../content-area/content-area';

import { PageStyled } from './page-content.styled';

/* eslint-disable-next-line */
export interface PageContentProps {
  content?: PageFragment['content'] | LandingPageFragment['content'];
  title?: string;
  description?: string;
  image?: string;
  children?: React.ReactNode;
  testId?: string;
  hrefLanguages?: Language[];
}

export function PageContent({
  content = [],
  title,
  description,
  image,
  children,
  testId,
  hrefLanguages,
}: PageContentProps) {
  const { site } = useGlobal();
  const { asPath } = useRouter();
  let suffix = '';
  if (
    !title?.toLocaleLowerCase()?.includes('eripiowear')
    && site.globalSeo?.titleSuffix
  ) {
    suffix = site.globalSeo.titleSuffix;
  }
  const pageTitle = `${title} ${suffix}`.trim();

  const url = asPath
    .split('?')[0]
    ?.replace(/( |%20)/g, asPath.startsWith('/products') ? '-' : ' ')
    ?.toLowerCase();

  function languagesMapper({
    lang,
    urlPrefix,
    hostname: marketHostname,
  }: Language) {
    const hrefLang = lang.toLowerCase();
    let path = url;
    if (urlPrefix) {
      path = `/${urlPrefix}${url}`;
    }
    const href = `https://${marketHostname}${path}`;
    if (hrefLang === 'en') {
      return (
        <link rel="alternate" hrefLang="x-default" href={href} key={href} />
      );
    }
    return <link rel="alternate" hrefLang={hrefLang} href={href} key={href} />;
  }

  return (
    <PageStyled data-test-id={testId}>
      <Head>
        <title>{pageTitle}</title>
        <meta property="og:title" content={title} />
        <meta property="twitter:title" content={title} />
        {description && (
          <>
            <meta name="description" content={description} />
            <meta property="og:description" content={description} />
            <meta name="twitter:description" content={description} />
          </>
        )}
        {image && (
          <>
            <meta property="og:image" content={image} />
            <meta name="twitter:image" content={image} />
          </>
        )}
        {hrefLanguages?.map(languagesMapper)}
      </Head>
      <ContentArea content={content || []} />
      {children}
    </PageStyled>
  );
}

export default PageContent;
