export const colors = {
  red: '#BA0C2F',
  gray1: '#5E5A59',
  gray2: '#747070',
  gray3: '#C4C2C3',
  gray4: '#F2F2F2',
  gray5: '#FAFAFA',
  grayBtnHover: '#E9E8E8',
  overlayTransparent: 'rgba(35, 33, 32, 0.3)',
  boxshadowTransparent: 'rgba(0, 0, 0, 0.1)',
  white: '#fff',
  solidBlack: '#000',
  solidBlackHover: '#111',
  nearBlack: '#232120',
  // Eripiowear
  orange: '#FB471F',
  black: '#2C2A29',
  grey1: '#A7A9B4',
  grey2: '#D9DAE4',
} as const;

export type Colors = keyof typeof colors;
