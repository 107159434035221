import ChevronSvg from './chevron.svg';
import { ReadMoreLinkStyled } from './read-more-link.styled';

interface ReadMoreLinkProps {
  href?: string | null;
  children?: React.ReactNode;
}

export const ReadMoreLink: React.FC<ReadMoreLinkProps> = ({
  href,
  children,
}) => {
  if (!href) {
    return null;
  }
  return (
    <ReadMoreLinkStyled href={href}>
      {children}
      <ChevronSvg />
    </ReadMoreLinkStyled>
  );
};
