import { CSSProperties } from 'styled-components';

import { ButtonStyled } from './button.styled';
import NextSvg from './next.svg';
export interface ButtonProps {
  children?: React.ReactNode;
  disabled?: boolean;
  onClick?: React.MouseEventHandler;
  className?: string;
  letterSpacing?: CSSProperties['letterSpacing'];
  uppercase?: boolean;
  iconNext?: boolean;
  small?: boolean;
  isLoading?: boolean;
  yellow?: boolean;
  grey?: boolean;
  ghost?: boolean;
}

export const Button: React.FC<ButtonProps> = ({
  letterSpacing = '2px',
  uppercase = true,
  children,
  iconNext,
  small,
  isLoading,
  yellow,
  grey,
  ghost,
  disabled,
  className,
  onClick,
}) => (
  <ButtonStyled
    data-test-id="Button"
    onClick={onClick}
    className={className}
    disabled={disabled}
    $letterSpacing={letterSpacing}
    $uppercase={uppercase}
    $iconNext={iconNext}
    $small={small}
    $isLoading={isLoading}
    $yellow={yellow}
    $grey={grey}
    $ghost={ghost}
  >
    {children}
    {iconNext && (
      <NextSvg width={7} height={12} aria-hidden={true} focusable={false} />
    )}
  </ButtonStyled>
);
