export const spacing = {
  huge: '6.25rem', // 100px
  large: '4.375rem', // 70px
  medium: '3.125rem', // 50px
  xMedium: '2rem', // 32px
  small: '1.5rem', // 24px
  xSmall: '1rem', // 16px
  regular: '1.5rem', // 24px
  tiny: '0.75rem', // 12px
  tinier: '0.5rem', // 8px
  xxSmall: '0.25rem', // 4px
} as const;

export type Spacing = keyof typeof spacing;
