import styled, { css } from 'styled-components';

import { colors, fontSizes, fontWeights } from '@hultafors/eripiowear/helpers';

interface BadgeStyledProps {
  $inline?: boolean;
  $round?: boolean;
  $small?: boolean;
  $yellow?: boolean;
  $tiny?: boolean;
  $large?: boolean;
  $disabled?: boolean;
}

export const BadgeStyled = styled.div<BadgeStyledProps>`
  position: ${({ $inline }) => ($inline ? 'relative' : 'absolute')};
  display: ${({ $inline }) => ($inline ? 'inline-block' : 'block')};
  font-size: ${fontSizes.mini2};
  font-weight: ${fontWeights.bold};
  letter-spacing: 0.5px;
  text-align: center;
  line-height: 18px;
  width: auto;
  height: 16px;
  text-transform: uppercase;
  padding-left: 12px;
  margin-block-end: ${({ $inline }) => ($inline ? '12px' : '0')};

  &::before {
    content: '';
    position: absolute;
    left: 0;
    display: block;
    width: 13px;
    height: 16px;
    background-image: url('/public/svg/yellow-line-badge.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left;
  }

  ${({ $round, $yellow }) =>
    $round
    && css`
      padding: 0;
      border-radius: 100%;
      background-color: ${$yellow ? colors.orange : colors.solidBlack};
      font-weight: ${fontWeights.normal};
      color: ${$yellow ? colors.solidBlack : colors.white};
      line-height: 40px;

      &::before {
        display: none;
      }
    `}

  ${({ $small }) =>
    $small
    && css`
      width: 32px;
      height: 32px;
      line-height: 32px;
    `}

${({ $large }) =>
    $large
    && css`
      width: 40px;
      height: 40px;
      font-size: ${fontSizes.m};
      line-height: 40px;
    `}

    ${({ $tiny }) =>
    $tiny
    && css`
      width: 18px;
      height: 18px;
      line-height: 20px;
    `}

${({ $disabled }) =>
    $disabled
    && css`
      opacity: 0.3;
    `}
`;
