import { startTransition } from 'react';

import Image from 'next/image';

import { ProductDetailsValue } from '@hultafors/shared/types';

import { ColorImageWrapper } from './product-color.styled';

export interface ProductColorProps {
  color: ProductDetailsValue;
  setColor?(color: ProductDetailsValue): void;
  selectedColor?: ProductDetailsValue;
  productColorImageSize?: number;
}

export const ProductColor: React.FC<ProductColorProps> = ({
  productColorImageSize,
  setColor,
  color,
  selectedColor,
}) => {
  const onClick = () => {
    startTransition(() => {
      setColor?.(color);
    });
  };

  const size = `${productColorImageSize || 32}px`;
  return color?.image?.url
    ? (
      <ColorImageWrapper
        as={setColor && 'button'}
        $productColorImageSize={productColorImageSize}
        onClick={setColor && onClick}
        $selected={selectedColor && selectedColor?.text === color.text}
      >
        <Image
          src={
          color.image.url.endsWith('.tif')
            ? `${color.image.url}?format=png`
            : color.image.url
        }
          alt={color.text}
          title={color.text}
          sizes={size}
          fill
        />
      </ColorImageWrapper>
      )
    : null;
};
