import { H1Styled } from './h1.styled';

export interface H1Props {
  children?: React.ReactNode;
  className?: string;
  center?: boolean;
}

export const H1: React.FC<H1Props> = ({
  center = false,
  className,
  children,
}) => (
  <H1Styled className={className} $center={center}>
    {children}
  </H1Styled>
);
