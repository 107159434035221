import Link from 'next/link';
import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, fontWeights, spacing } from '@hultafors/eripiowear/helpers';

export const DesktopMenuStyled = styled.div`
  display: none;

  @media screen and (min-width: ${breakpoints.desktop}) {
    display: block;
  }
`;

export const MenuContent = styled.div`
  position: relative;
  display: flex;
  box-sizing: border-box;
  align-items: flex-end;
  justify-content: center;
  background-color: ${colors.white};
`;

export const MenuContentItem = styled.div`
  box-sizing: border-box;
  display: block;
  width: 100%;
  margin: 0 auto;
  padding: 0 3em 3rem;
  max-width: ${breakpoints.maxPageWidth};

  .Result {
    top: 110px;
  }
`;
export const MenuSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${spacing.tinier};
`;

export const LinkList = styled.div`
  list-style: none;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: ${spacing.tinier};
`;

export const LinkListHeader = styled(Link)`
  font-weight: ${fontWeights.normal};
  text-decoration: none;
`;

export const LinkListHeaderNoLink = styled.span`
  font-weight: ${fontWeights.normal};
`;

export const MenuItems = styled.div`
  box-sizing: border-box;
`;

export const LinkListLink = styled(Link)`
  font-weight: ${fontWeights.normal};
  font-size: 14px;
  text-decoration: none;
  display: block;
`;

export const TabContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

export const TabContent = styled.div`
  display: flex;
  width: 100%;
`;

export const TabItems = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 16px;
  width: 100%;
  flex-direction: row;
  align-content: flex-start;
`;

export const FirstTwoTabItems = styled.div`
  > * + * {
    margin-block-start: 24px;
  }
`;

export const SubMenu = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;

  > * {
    width: 100%;
    max-width: ${1248 / 7}px;
  }
`;
