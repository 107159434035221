import styled, { css } from 'styled-components';

import { colors, fontSizes, spacing } from '@hultafors/eripiowear/helpers';

interface TextButtonStyledProps {
  $iconUrl?: string;
  $iconLeft?: boolean;
  $center?: boolean;
}

export const TextButtonStyled = styled.button<TextButtonStyledProps>`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0 ${spacing.small};
  background-color: transparent;
  border: none;
  appearance: none;
  font-size: ${fontSizes.body};

  p {
    color: ${colors.solidBlack};
  }

  :hover {
    cursor: pointer;

    p {
      color: ${colors.solidBlackHover};
    }
  }

  &:focus {
    outline: none;
  }
  ${({ $iconUrl, $iconLeft }) =>
    $iconUrl
    && !$iconLeft
    && css`
      &::after {
        content: '';
        display: none;
        display: inline-flex;
        width: ${spacing.small};
        height: ${spacing.small};
        background-image: url(${$iconUrl});
        background-repeat: no-repeat;
        background-size: contain;
        margin-left: ${spacing.xSmall};
        transition: transform 0.1s linear;
      }
    `}

  ${({ $iconUrl, $iconLeft }) =>
    $iconUrl
    && $iconLeft
    && css`
      &::before {
        content: '';
        display: none;
        display: inline-flex;
        width: ${spacing.small};
        height: ${spacing.small};
        background-image: url(${$iconUrl});
        background-repeat: no-repeat;
        background-size: contain;
        margin-inline-end: ${spacing.xSmall};
        transition: transform 0.1s linear;
      }
    `}
`;
