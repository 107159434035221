import { AnimatePresence, m } from 'framer-motion';

import { useGlobal } from '@hultafors/eripiowear/hooks';
import {
  LinkListFragment,
  PageLinkFragment,
  SubMenuSectionFragment,
} from '@hultafors/eripiowear/types';

import { SearchBox } from '../search-box/search-box';
import { SlideIn } from '../slide-in/slide-in';

import {
  DesktopMenuStyled,
  LinkList,
  LinkListHeader,
  LinkListLink,
  MenuContentItem,
  MenuItems,
  MenuSection,
  SubMenu,
} from './desktop-menu.styled';

export const DESKTOP_MENU_DRAWER_ID = 'DesktopMenuDrawer';
export const DESKTOP_MENU_TYPE_CATEGORY = 'category' as const;
export const DESKTOP_MENU_TYPE_SEARCH = 'search' as const;
export type DesktopMenuType = 'search' | 'category';

export interface DesktopMenuProps {
  isOpen: boolean;
  type: DesktopMenuType | null;
  toggleDesktopMenu(...args: any[]): unknown;
  settings?: unknown;
}

export const DesktopMenu: React.FC<DesktopMenuProps> = ({
  toggleDesktopMenu,
  isOpen,
  type,
}) => {
  const { allMenuItems } = useGlobal();
  const productsMenu = allMenuItems?.find(({ sections }) => !!sections?.length);

  function sectionMapper(section: SubMenuSectionFragment) {
    return (
      <MenuSection key={`DesktopMenu-Section-${section.id}`}>
        {section.content.map(linkListMapper)}
      </MenuSection>
    );
  }

  function linkListMapper(list: LinkListFragment) {
    if (!list.link && !list.items.length) {
      return null;
    }
    const key = `LinkListBlock-${list.id}`;
    function subMenuItemMapper(link: PageLinkFragment) {
      const href = link.slug;
      if (!href) {
        return null;
      }
      const key = `SubMenuLink-${link.id}`;
      if (list.link?.slug) {
        return (
          <LinkListLink key={key} href={href}>
            {link?.shortTitle || link?.title || href}
          </LinkListLink>
        );
      }
      return (
        <LinkListHeader key={key} href={href}>
          {link?.shortTitle || link?.title || href}
        </LinkListHeader>
      );
    }
    return (
      <LinkList key={key}>
        {list.link?.slug && (
          <LinkListHeader href={list.link.slug}>
            {list.link?.shortTitle ?? list.link?.title ?? list.link.slug}
          </LinkListHeader>
        )}
        {list.items.map(subMenuItemMapper)}
      </LinkList>
    );
  }

  const sections: SubMenuSectionFragment[]
    = productsMenu?.sections?.filter(Boolean) || [];

  return (
    <DesktopMenuStyled>
      <SlideIn
        toggle={toggleDesktopMenu}
        isOpen={isOpen}
        fromTop
        small={type === 'search'}
        id={DESKTOP_MENU_DRAWER_ID}
      >
        <AnimatePresence>
          {type === 'search' && (
            <m.div
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: 'auto', opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
              transition={{ duration: 0.3 }}
            >
              <MenuContentItem>
                <SearchBox
                  wide
                  toggleMenu={toggleDesktopMenu}
                  isOpen={isOpen}
                  isAutoComplete={true}
                />
              </MenuContentItem>
            </m.div>
          )}
        </AnimatePresence>
        <AnimatePresence>
          {type === DESKTOP_MENU_TYPE_CATEGORY && (
            <m.div
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: 'auto', opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
              transition={{ duration: 0.3 }}
            >
              <MenuContentItem>
                <MenuItems>
                  {/* <TabContentWrapper> */}
                  {/* <TabContent id={'TabContent'}> */}
                  {!!sections?.length && (
                    <SubMenu>{sections.map(sectionMapper)}</SubMenu>
                  )}
                  {/* </TabContent> */}
                  {/* </TabContentWrapper> */}
                </MenuItems>
              </MenuContentItem>
            </m.div>
          )}
        </AnimatePresence>
      </SlideIn>
    </DesktopMenuStyled>
  );
};
