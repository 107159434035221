import { CSSProperties } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { Spacing } from '@hultafors/eripiowear/helpers';

import { GridColumnGap, GridStyled } from './grid.styled';

export interface GridProps {
  children?: React.ReactNode;
  className?: string;
  align?: CSSProperties['alignItems'];
  margin?: CSSProperties['margin'];
  maxWidth?: CSSProperties['maxWidth'];
  center?: boolean;
  columnGap?: GridColumnGap[] | Spacing | number;
  columns?: GridColumnGap[] | number | 'auto-fit' | 'auto-fill';
  minColumnWidth?: string;
  rowGap?: Spacing | number;
}

export const Grid: React.FC<GridProps> = ({
  children,
  align = 'stretch',
  columnGap = [{ columnGap: 40 }, { breakpoint: 'mobileMax', columnGap: 20 }],
  margin = '0 auto',
  columns = [{ columns: 4 }, { breakpoint: 'mobileMax', columns: 12 }],
  maxWidth = `${breakpoints.maxPageWidth}`,
  minColumnWidth,
  rowGap = 0,
  className,
}) => {
  return (
    <GridStyled
      $align={align}
      $columnGap={columnGap}
      $columns={columns}
      $margin={margin}
      $maxWidth={maxWidth}
      $minColumnWidth={minColumnWidth}
      $rowGap={rowGap}
      className={className}
    >
      {children}
    </GridStyled>
  );
};
