import styled from 'styled-components';

import { fontWeights, spacing } from '@hultafors/eripiowear/helpers';

import { Button } from '../button/button';

export const SubscribeText = styled.div`
  font-weight: ${fontWeights.normal};
  margin-block-end: ${spacing.regular};
`;

export const SubscribeButton = styled(Button)`
  width: fit-content;
`;
