import {
  ComponentType,
  startTransition,
  useEffect,
  useRef,
  useState,
} from 'react';

import { Portal } from 'react-portal';
import { useClickAway, useKey } from 'react-use';

import { useLockBodyScroll } from '@hultafors/shared/hooks';

import { useGlobal } from '@hultafors/eripiowear/hooks';

import BasketSvg from '../../svg/basket.svg';

import ClearSvg from './clear.svg';
import CloseSvg from './close.svg';
import PartnerSvg from './partner.svg';
import {
  BackArrow,
  Clear,
  ClearLabel,
  Close,
  CloseButton,
  ExternalLink,
  ExternalLinksContainer,
  HeaderIconContainer,
  HeaderText,
  HeaderTextInner,
  SlideInBackground,
  SlideInBody,
  SlideInContainer,
  SlideInExit,
  SlideInHeader,
  SlideInInside,
  slideInTime,
} from './slide-in.styled';

export type SlideInToggler<T = string> = (key?: T | null) => void;

interface SlideInProps {
  children?: React.ReactNode;
  backText?: string;
  isOpen: boolean;
  toggle: SlideInToggler;
  clearAll?(): void;
  headerText?: string;
  HeaderIcon?: ComponentType<
    React.SVGProps<SVGSVGElement> & { title?: string }
  >;
  clearText?: string;
  currentStepNumber?: number | null;
  isMenu?: boolean;
  isCart?: boolean;
  partnerPortalUrl?: string;
  partnerLabel?: string;
  shopLinkUrl?: string;
  shopLinkLabel?: string;
  changeLanguageText?: string;
  itemLength?: number;
  id: string;
  className?: string;
  onShowStart?(): void;
  onShowComplete?(): void;
  onHideStart?(): void;
  onHideComplete?(): void;
  changeStepWG?(stepNumber: number): void;
  changeStepSG?(currentStep: number, goBack: boolean): void;

  fromLeft?: boolean;
  small?: boolean;
  fromTop?: boolean;
  isGuide?: boolean;
  padContent?: boolean;
}

export const SlideIn: React.FC<SlideInProps> = ({
  children,
  backText,
  isOpen,
  isMenu,
  isCart,
  fromLeft = true,
  fromTop,
  small,
  padContent,
  isGuide,
  clearText = '',
  headerText = '',
  HeaderIcon,
  partnerPortalUrl = '',
  partnerLabel = 'Partner',
  shopLinkUrl,
  shopLinkLabel,
  itemLength,
  clearAll,
  toggle,
  id = '',
  onShowStart,
  onShowComplete,
  onHideStart,
  onHideComplete,
  changeStepWG,
  changeStepSG,
  currentStepNumber,
}) => {
  const { globalContent } = useGlobal();
  const ref = useRef<HTMLDivElement>(null);
  const innerRef = useRef(null);
  const nodeRef = useRef<Element | null | undefined>(null);
  const [animating, setAnimating] = useState<boolean>(false);
  const [active, setActive] = useState<boolean>(false);
  const [initialized, setInitialized] = useState<boolean>(false);
  const [animationTimer, setAnimationTimer] = useState<NodeJS.Timeout>();
  useLockBodyScroll(active);

  useEffect(() => {
    nodeRef.current = document.querySelector('#portal');
  }, []);

  const close = () => {
    if (isOpen) {
      toggle(null);
    }
  };

  useKey('Escape', () => {
    if (isOpen) {
      close();
    }
  });

  // TODO: Figure out click away
  useClickAway(innerRef, (event) => {
    const target = event.target as HTMLButtonElement;
    if (
      isOpen
      && (!target?.dataset?.['slideinIgnore']
      || (id && target?.dataset?.['slideinIgnore'] !== id))
    ) {
      close();
    }
  });

  useEffect(() => {
    if (initialized) {
      startTransition(() => {
        setAnimating(true);
      });
      if (isOpen) {
        startTransition(() => {
          setActive(true);
        });
        if (onShowStart) {
          onShowStart();
        }
        startTransition(() => {
          setAnimationTimer(
            setTimeout(() => {
              setAnimating(false);
              if (onShowComplete) {
                onShowComplete();
              }
            }, slideInTime),
          );
        });
      } else {
        if (onHideStart) {
          onHideStart();
        }
        startTransition(() => {
          setAnimationTimer(
            setTimeout(() => {
              if (onHideComplete) {
                onHideComplete();
              }
              setActive(false);
              setAnimating(false);
            }, slideInTime),
          );
        });
      }
    } else {
      startTransition(() => {
        setInitialized(true);
      });
    }

    return () => {
      clearTimeout(animationTimer);
    };
  }, [isOpen]);

  useEffect(() => {
    return () => {
      startTransition(() => {
        setActive(false);
      });
    };
  }, []);

  if (!initialized) {
    return null;
  }

  return (
    <Portal node={nodeRef.current}>
      <SlideInContainer
        $active={active}
        $isOpen={isOpen}
        $fromTop={fromTop}
        ref={ref}
        aria-hidden={!isOpen}
      >
        <SlideInBackground
          $active={active}
          $fromTop={fromTop}
          $isOpen={isOpen}
        />
        <SlideInInside
          $active={active}
          $isOpen={isOpen}
          $fromLeft={fromLeft}
          $fromTop={fromTop}
          $small={small}
          ref={innerRef}
          $animating={animating}
        >
          <SlideInHeader $isGuide={isGuide} $fromTop={fromTop}>
            {isGuide
            && currentStepNumber
            && currentStepNumber !== 0
            && (changeStepWG || changeStepSG)
              ? (
                <button
                  className="BackButton"
                  onClick={() =>
                    changeStepWG
                      ? changeStepWG(currentStepNumber - 1)
                      : changeStepSG && changeStepSG(0, true)}
                >
                  <BackArrow />
                  {backText}
                </button>
                )
              : null}
            <HeaderText $isGuide={isGuide}>
              {isMenu
                ? (
                  <>
                    <Close
                      $isMenu={isMenu}
                      onClick={() => toggle('')}
                      aria-label={globalContent.close || 'Close'}
                    >
                      <CloseSvg
                        focusable="false"
                        aria-hidden="true"
                        width={isMenu ? 16 : 24}
                        height={isMenu ? 16 : 24}
                      />
                    </Close>
                    <ExternalLinksContainer>
                      {partnerPortalUrl && (
                        <ExternalLink
                          href={partnerPortalUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {partnerLabel}
                          <PartnerSvg focusable="false" aria-hidden="true" />
                        </ExternalLink>
                      )}
                      {shopLinkUrl && shopLinkLabel && (
                        <ExternalLink
                          href={shopLinkUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {shopLinkLabel}
                          <BasketSvg aria-hidden={true} focusable={false} />
                        </ExternalLink>
                      )}
                    </ExternalLinksContainer>
                  </>
                  )
                : (
                  <HeaderIconContainer $isGuide={isGuide}>
                    {HeaderIcon && (
                      <HeaderIcon aria-hidden={true} width={24} height={24} />
                    )}
                    <HeaderTextInner>{headerText}</HeaderTextInner>
                  </HeaderIconContainer>
                  )}
            </HeaderText>

            <SlideInExit $isGuide={isGuide}>
              {clearAll && clearText && !!itemLength && (
                <Clear onClick={clearAll} key="ClearButton">
                  <ClearLabel>{clearText}</ClearLabel>
                  <ClearSvg
                    focusable="false"
                    aria-hidden="true"
                    width={16}
                    height={16}
                  />
                </Clear>
              )}

              {!isMenu && (
                <CloseButton onClick={() => toggle('')}>
                  <CloseSvg focusable="false" aria-hidden="true" />
                </CloseButton>
              )}
            </SlideInExit>
          </SlideInHeader>

          <SlideInBody
            $isCart={isCart}
            $fromTop={fromTop}
            $padContent={padContent}
          >
            {children}
          </SlideInBody>
        </SlideInInside>
      </SlideInContainer>
    </Portal>
  );
};
