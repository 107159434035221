import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, fontSizes, spacing } from '@hultafors/eripiowear/helpers';

export const ImageAttributeStyled = styled.div`
  .ImgWrapper {
    display: flex;
    height: 24px;
    width: 24px;
    margin-inline-end: 16px;

    img {
      max-width: 24px;
      max-height: 24px;
    }
  }

  h4.Title {
    display: flex;
    align-items: center;
    font-size: ${fontSizes.body};
    line-height: 22px;
    margin-block-start: 16px;

    @media screen and (min-width: ${breakpoints.desktop}) {
      font-size: ${fontSizes.m};
    }

    &.Certifications {
      margin-block-end: 12px;
    }

    img {
      max-width: 24px;
      max-height: 24px;
      margin-inline-end: 16px;
    }
  }

  .Attribute p,
  .DocumentWrapper p {
    white-space: pre-wrap;
    margin-block: 0 0;
    font-size: ${fontSizes.body};

    @media screen and (min-width: ${breakpoints.desktop}) {
      font-size: ${fontSizes.m};
    }
  }

  .Attribute,
  .DocumentWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-block: ${spacing.regular} ${spacing.regular};
    padding-inline-end: ${spacing.regular};
    box-sizing: border-box;

    &.Certifications {
      padding-left: 37px;
      padding-block: 0 0;

      &:last-of-type {
        margin-block-end: ${spacing.regular};
      }

      p {
        font-size: ${fontSizes.body};
        margin-block-end: 8px;

        @media screen and (min-width: ${breakpoints.desktop}) {
          font-size: ${fontSizes.m};
        }
      }
    }

    &.Care {
      padding-block-end: 8px;

      &:last-of-type {
        margin-block-end: 24px;
      }
    }

    &.Cobranding {
      flex-direction: column;
      align-items: flex-start;
      border-top: thin solid ${colors.gray4};
      padding-block-start: ${spacing.regular};

      .ImgWrapper {
        width: auto;
        height: auto;
        margin-block-end: ${spacing.regular};

        img {
          height: 40px;
          max-width: 100%;
          max-height: 100%;
        }
      }
    }

    &:first-of-type:not(.Cobranding) {
      padding-block-start: 0;
    }

    a {
      text-decoration: none;

      &:hover {
        color: ${colors.gray1};
      }
    }
  }
`;
