import { startTransition, useState } from 'react';

import { ImageBasicFragment } from '@hultafors/eripiowear/types';

import { SubscriptionDialog } from '../subscription-dialog/subscription-dialog';

import { SubscribeButton, SubscribeText } from './subscribe-area.styled';

export interface SubscribeAreaProps {
  subscribeInfoText?: string;
  subscribeButtonText?: string;
  userEmailFormUrl?: string;
  userEmailFormImage?: ImageBasicFragment | null;
}

export const SubscribeArea: React.FC<SubscribeAreaProps> = ({
  subscribeInfoText,
  subscribeButtonText,
  userEmailFormUrl,
  userEmailFormImage,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const toggleDialog = () => {
    startTransition(() => {
      setIsDialogOpen(!isDialogOpen);
    });
  };

  return (
    <>
      <SubscribeText>{subscribeInfoText}</SubscribeText>
      <SubscribeButton onClick={toggleDialog} yellow>
        {subscribeButtonText}
      </SubscribeButton>

      {isDialogOpen && (
        <SubscriptionDialog
          formUrl={userEmailFormUrl}
          image={userEmailFormImage}
          onHandleClose={toggleDialog}
        />
      )}
    </>
  );
};
