import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { fontWeights } from '@hultafors/eripiowear/helpers';

export const RelatedProductsStyled = styled.div`
  .ButtonWrapper {
    display: flex;
    justify-content: center;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    margin-block-start: 30px;
  }
`;

export const Heading = styled.h2`
  font-size: 22px;
  line-height: 28px;
  font-weight: ${fontWeights.normal};
  text-transform: uppercase;
  text-align: center;
  margin-block: 10px 30px;
  letter-spacing: 1px;

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    margin-block: 20px 45px;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    margin-block-start: 0;
  }
`;
