import { startTransition, useEffect, useState } from 'react';

import { useRouter } from 'next/router';

import { useGlobal } from '@hultafors/eripiowear/hooks';

import { DesktopMenu, DesktopMenuType } from '../desktop-menu/desktop-menu';
import { Favorites } from '../favorites/favorites';
import { Menu } from '../menu/menu';
import { Navigation } from '../navigation/navigation';
import { SlideInToggler } from '../slide-in/slide-in';

import { HeaderStyled } from './header.styled';

export interface OpenSettings {
  desktopMenu: boolean;
  mobileMenu: boolean;
  favorites: boolean;
  cart: boolean;
}

const defaultOpenSettings: OpenSettings = {
  desktopMenu: false,
  mobileMenu: false,
  favorites: false,
  cart: false,
};

export const Header: React.FC = () => {
  const { globalContent, allMenuItems } = useGlobal();

  const [openDesktopMenuType, setOpenDesktopMenuType]
    = useState<DesktopMenuType | null>(null);
  const [isOpen, setIsOpen] = useState<OpenSettings>(defaultOpenSettings);

  const { pathname, events } = useRouter();

  useEffect(() => {
    const closeMenu = () =>
      startTransition(() => {
        setOpenDesktopMenuType(null);
        setIsOpen(defaultOpenSettings);
      });
    events.on('routeChangeStart', closeMenu);
    return () => {
      events.off('routeChangeStart', closeMenu);
    };
  }, [events]);

  const toggleMenu: SlideInToggler = () => {
    const open = !isOpen.mobileMenu;
    startTransition(() => {
      setIsOpen({ ...defaultOpenSettings, mobileMenu: open });
    });
  };

  const toggleDesktopMenu: SlideInToggler<DesktopMenuType> = (
    type?: DesktopMenuType | null,
  ) => {
    startTransition(() => {
      setOpenDesktopMenuType(
        !type || openDesktopMenuType === type ? null : type,
      );
    });
  };

  useEffect(() => {
    startTransition(() => {
      setIsOpen({
        ...isOpen,
        desktopMenu: !!openDesktopMenuType,
      });
    });
  }, [openDesktopMenuType]);

  const toggleFavoritesMenu = (force?: boolean) => {
    const open = typeof force !== 'undefined' ? force : !isOpen.favorites;
    startTransition(() => {
      setIsOpen({ ...defaultOpenSettings, favorites: open });
    });
  };

  // Ugly hack to set transparent menu when moving Nav to root
  const isMenuTransparent = () => {
    if (pathname.startsWith('/interviews')) {
      return true;
    }
    return false;
  };

  if (!globalContent) {
    return null;
  }

  return (
    <HeaderStyled>
      <Navigation
        isTransparent={isMenuTransparent()}
        isOpen={isOpen.desktopMenu}
        openDesktopMenuType={openDesktopMenuType}
        toggleMenu={toggleMenu}
        toggleDesktopMenu={toggleDesktopMenu}
        toggleFavoritesMenu={toggleFavoritesMenu}
        menuData={allMenuItems}
      />

      <Menu
        isOpen={isOpen.mobileMenu}
        toggleMenu={toggleMenu}
        headerText={globalContent?.menu || ''}
        // menuData={allMenuItems}
      />

      <DesktopMenu
        isOpen={isOpen.desktopMenu}
        type={openDesktopMenuType}
        // menuItems={allMenuItems}
        toggleDesktopMenu={toggleDesktopMenu}
      />

      <Favorites
        isOpen={!!isOpen.favorites}
        toggleMenu={toggleFavoritesMenu}
        header={globalContent.favorites || ''}
        clearText={globalContent.clear || ''}
        newText={globalContent.new || ''}
      />
    </HeaderStyled>
  );
};
