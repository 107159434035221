import styled, { css, CSSProperties } from 'styled-components';

import { Breakpoint, breakpoints } from '@hultafors/shared/constants';

export interface GridChildColumnSpan {
  columns?: number;
  breakpoint?: Breakpoint;
  columnGap?: number;
  start?: number;
  display?: CSSProperties['display'];
}
type GridChildColumnSpanProp = number | GridChildColumnSpan[];

interface GridChildStyledProps {
  $columnSpan?: GridChildColumnSpanProp;
  $align?: CSSProperties['justifySelf'];
  $alignSelf?: CSSProperties['alignSelf'];
  $padding?: CSSProperties['padding'];
  $rowSpan?: string;
  $rowStart?: string;
}

function getColumnSpanMapper({
  breakpoint,
  start,
  columns,
  display,
}: GridChildColumnSpan) {
  if (!breakpoint) {
    const gridStart = start ? `${start} /` : '';
    const end = columns ? `span ${columns}` : -1;
    return css`
      grid-column: ${gridStart} ${end};
      display: ${display ? display : 'initial'};
    `;
  }

  const gridStart = start ? `${start} /` : '';
  const end = columns ? `span ${columns}` : -1;
  return css`
    @media screen and (min-width: ${breakpoints[breakpoint]}) {
      grid-column: ${gridStart} ${end};
      display: ${display ? display : 'initial'};
    }
  `;
}

function getColumnSpan({ $columnSpan }: GridChildStyledProps) {
  if (typeof $columnSpan === 'object') {
    return $columnSpan.map(getColumnSpanMapper);
  }
  return css`
    grid-column: ${$columnSpan ? $columnSpan : '1 / -1'};
  `;
}
export const GridChildStyled = styled.div<GridChildStyledProps>`
  grid-row: ${({ $rowStart = '' }) => `${$rowStart}`} /
    ${({ $rowSpan }) => $rowSpan && `span ${$rowSpan}`};
  ${getColumnSpan};
  place-self: ${({ $alignSelf }) => $alignSelf} ${({ $align }) => $align};
  padding: ${({ $padding }) => $padding};
`;
