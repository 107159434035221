import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  fontSizes,
  fontWeights,
  lineHeights,
} from '@hultafors/eripiowear/helpers';

interface ProductNameStyledProps {
  $center?: boolean;
}

export const ProductNameStyled = styled.h1<ProductNameStyledProps>`
  font-size: ${fontSizes.productMobile};
  line-height: ${lineHeights.productMobile};
  font-weight: ${fontWeights.normal};
  text-align: ${({ $center }) => ($center ? 'center' : '')};
  letter-spacing: 0.5px;

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    font-size: ${fontSizes.h2};
    line-height: ${lineHeights.productDesktop};
    font-weight: ${fontWeights.normal};
    text-align: ${({ $center }) => ($center ? 'center' : '')};
  }
`;
